import makeUrls, { TCalendarEvent } from "add-event-to-calendar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import TextButton from "../../../components/TextButton";
import TextWrapper from "../../../components/TextWrapper";
import theme from "../../../theme";
import { IPeopleOfEdenDetails, ITiers } from "../../config";
import { getRegistrationStatus } from "../../utils/Status";

interface IProps {
  projectId: string;
  projectDetails: IPeopleOfEdenDetails;
  tierId: string;
  tierDetails: ITiers;
}


const RegisterButton = (props: IProps) => {
  // @ts-ignore
  const registrationDaysLeft = new moment().to(moment(new Date(props.tierDetails.registrationDates.endDate * 1000)));
  const registrationStatus = getRegistrationStatus({
    startDate: props.tierDetails.registrationDates.startDate,
    endDate: props.tierDetails.registrationDates.endDate
  });
  const [calendarLink, setLink] = useState('');

  useEffect(() => {
    const onClick = () => {
      let event: TCalendarEvent = {
        name: `${props.projectDetails.displayName.toUpperCase()} | ${props.tierDetails.displayName.toUpperCase()} Registration`,
        location: `https://${window.location.hostname}/#/projects/${props.projectId.toLowerCase()}`,
        details: `Reminder for ${props.tierDetails.displayName.toUpperCase()} Registration`,
        startsAt: new Date(props.tierDetails.registrationDates.startDate * 1000).toUTCString(),
        endsAt: new Date(props.tierDetails.registrationDates.endDate * 1000).toUTCString(),
      };
      setLink(makeUrls(event).google);
    };
    onClick();
  }, [props.projectId, props.projectDetails, props.tierDetails]);

  return (
    <div className={'m-t-32'}>
      {registrationStatus === 'Registration started' &&
        <TextWrapper text={`Registration closes in ${registrationDaysLeft}`} align={'center'}
          Fcolor={theme.color.transparent[100]} />}
      {registrationStatus === 'Registration starting soon' &&
        <TextWrapper text={`Registration starts in ${registrationDaysLeft}`} align={'center'}
          Fcolor={theme.color.transparent[100]} className={'m-b-2'} />}
      {registrationStatus === 'Registration starting soon' &&
        <TextButton onClick={() => window.open(calendarLink, '_blank')} text={'Set Reminder'} align={'center'} />}
      <div className={'m-t-4'}>
        <Button
          onClick={() => window.open(props.tierDetails.registerLink, '_blank')}
          disabled={registrationStatus !== "Registration started"}
          tracking_id={'Register'}
          text={'Register'}
        />
      </div>

    </div>
  )
}

export default RegisterButton;
