import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import Home from "./Home";
import {PeopleOfEden} from "./PeopleOfEden/config";
import PeopleOfEdenDetails from "./PeopleOfEden/pages/Details";
import PeopleOfEdenTiersList from "./PeopleOfEden/pages/TiersList";

const Navigation = () => {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home/>
        </Route>
        <Route exact path="/tiers/people-of-eden/">
          <PeopleOfEdenTiersList/>
        </Route>
        {Object.keys(PeopleOfEden.tiers).map((id: string, details: number) => {
          return (
            <Route exact path={`/project/people-of-eden/${id}`} key={id}>
              <PeopleOfEdenDetails tiersId={id}/>
            </Route>
          )
        })}
        <Redirect to="/"/>
      </Switch>
    </>

  );
}

export default Navigation;
