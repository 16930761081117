import {Grid} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import Divider from "../../../components/Divider";
import TextWrapper from "../../../components/TextWrapper";
import theme from "../../../theme";
import {PeopleOfEden} from "../../config";
import LaunchingOn from "../LaunchingOn";
import ListingOn from "../ListingOn";
import Price from "../Price";
import ProjectCardStatusTag from "../ProjectStatusTag";
import RegistrationStatusTag from "../RegistrationStatusTag";
import TargetMint from "../TargetMint";

const NFTProjectCard = () => {

  const projectId = 'PeopleOfEden';
  const details = PeopleOfEden;
  const onProjectClick = () => window.location.href = `/#/tiers/${details.projectUrlKey}`;

  return (
    <div>
      <Card
        className="material-quaternary w-100 pointer"
        onClick={onProjectClick}
      >
        <Grid container spacing={2} direction='column'>
          <Grid item xs={12}>
            <div className="single-line-center-start m-b-12">
              <img src={details.logo} width={64} height={64} className={'rounded-50 m-r-24'} alt={details.displayName}/>
              <div>
                <TextWrapper
                  text={details.displayName.toUpperCase()}
                  fontSize={24}
                  fontWeight={600}
                  lineHeight={'32px'}
                />
              </div>
            </div>
            <div className={"m-b-24"}>
              <TextWrapper
                text={details.desc}
                lineHeight={'24px'}
                fontSize={16}
                Fcolor={theme.color.transparent[100]}
              />
            </div>
            <div className="single-line-center-between">
              <div>
                <ProjectCardStatusTag projectId={projectId} startDate={details.dates.startDate}
                                      endDate={details.dates.endDate} className={'m-b-4'}/>
                <RegistrationStatusTag projectId={projectId} startDate={details.registrationDates.startDate}
                                       endDate={details.registrationDates.endDate}/>
              </div>
              <div className={'single-line-center-end'}>
                <LaunchingOn value={details.chain}/>
                {details.listingOn.map((data, index) => {
                  return <ListingOn value={data} key={index}/>
                })}
              </div>
            </div>
          </Grid>
          <Divider/>
          <Grid>
            <MobileCard>
              <div>
                <TargetMint value={details.targetMint}/>
                <Price value={details.price}/>
              </div>
            </MobileCard>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default NFTProjectCard;

const Card = styled.div`
  height: 100%;
  background: linear-gradient(to top, #2d2d2d20 0%, #1c1c1c 100%), url('http://45.79.202.81:8888/static/media/footer-bg.3340a456c2da9969b553.png');
  background-size: cover;
  transition: 0.5s ease-in-out;
  min-height: 420px;
  @media (max-width: 600px) {
    border-radius: 0;
    padding: 32px 26px;
  }
`;

export const Tag = styled.div<{ color: string; bg: string }>`
  padding: 4px 8px;
  border-radius: 60px;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  background: ${(props) => {
    return props.bg;
  }};
  color: ${(props) => {
    return props.color;
  }};
`;

const MobileCard = styled.div`
  border-radius: 6px;
    /* background: ${theme.color.transparent[500]}; */
  padding: 0 20px 0 20px;
  @media (max-width: 600px) {
    border-radius: 0;
    background: transparent;
    padding: 0;
  }
`;

