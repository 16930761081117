import DataField from "../../../components/DataField";
import theme from "../../../theme";

interface IProps {
  value: number;
}

const TargetMint = (props: IProps) => {
  return (
    <div className="m-b-16">
      <DataField
        label={"Target mint"}
        isValueLoading={false}
        value={`${props.value.toLocaleString('en-us')} NFTs`}
        labelFontSize={18}
        labelFontColor={theme.color.transparent[100]}
        valueFontSize={18}
        valueFontColor={"white"}
        valueFontWeight={600}
      />
    </div>
  );
}

export default TargetMint;
