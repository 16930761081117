import Countdown from "react-countdown";
import styled from "styled-components";
import IconLoader from "../../../components/IconLoader";

import theme from "../../../theme";
import {getStatus} from "../../utils/Status";

interface IProps {
  projectId: string;
  startDate: number;
  endDate: number;
  className?: string;
}

function ProjectCardStatusTag(props: IProps) {

  const startAndEndDate = {
    startDate: props.startDate,
    endDate: props.endDate,
  }

  const status = getStatus(startAndEndDate);

  if (status === "upcoming") {
    return (
      <Tag
        color={theme.color.yellow[500]}
        bg={theme.color.yellow[100]}
        className={props.className}
      >
        Upcoming
      </Tag>
    );
  } else if (status === "24hrsLeft") {
    return (
      <Tag
        color={theme.color.yellow[500]}
        bg={theme.color.yellow[100]}
        className={props.className}
      >
        <div className="single-line-center-start">
          <IconLoader iconName={"ProposalPending"} className="m-l-4"/>
          <Countdown
            date={startAndEndDate.endDate * 1000}
            renderer={({hours, minutes, seconds}) => {
              return `${hours}h ${minutes}m ${seconds}s`
            }}
          />
        </div>
      </Tag>
    );
  } else if (status === "live") {
    return (
      <Tag
        color={theme.color.green[500]}
        bg={theme.color.green[100]}
        className={props.className}
      >
        Live
      </Tag>
    );
  } else {
    return (
      <Tag
        color={theme.color.red[500]}
        bg={theme.color.red[100]}
        className={props.className}
      >
        closed
      </Tag>
    );
  }
}

export default ProjectCardStatusTag

export const Tag = styled.div<{ color: string; bg: string }>`
  padding: 4px 8px;
  border-radius: 60px;
  width: max-content;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  background: ${(props) => {
    return props.bg;
  }};
  color: ${(props) => {
    return props.color;
  }};
`;
