import {useCallback, useEffect, useState} from "react";
import {ITiers} from "../../../PeopleOfEden/config";
import {useGetUsersProof} from "../../../PeopleOfEden/utils/useGetUsersProof";
import {
  LOADING_DEFAULT_BASICBOOL_STATE,
  NON_LOADING_DEFAULT_BASICBOOL_STATE,
} from "../../../utils/constants";
import {BasicBoolState} from "../../../utils/interface";
import {useGetAccount, useGetChainId} from "../../../utils/NetworksCustomHooks";
import useCore from "../../useCore";

const useGetIsMinted = (tierDetails: ITiers) => {
  const [value, setValue] = useState<BasicBoolState>(
    LOADING_DEFAULT_BASICBOOL_STATE
  );

  const core = useCore();
  const account = useGetAccount();
  const chainId = useGetChainId();

  const fetchData = useCallback(async () => {
    const contract = core.getClaimingContract(chainId, tierDetails.contract);

    if (!account || contract === undefined) {
      setValue(NON_LOADING_DEFAULT_BASICBOOL_STATE);
      return;
    }

    const result: boolean = await contract.hasMinted(account);

    setValue({
      isLoading: false,
      value: result
    })

  }, [account, chainId, core, tierDetails.contract]);

  useEffect(() => {
    if (core) {
      fetchData().catch((err) => {
        setValue(NON_LOADING_DEFAULT_BASICBOOL_STATE);
        console.error(`Failed to fetch minted status: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useGetIsMinted;
