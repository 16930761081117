import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';

import '../../customCss/Custom-Mahadao-Tabs.css';

interface Iprops {
  selectedTab: number;
  onTabChange: (index: number) => void;
  data: any;
}

const CustomTab = (props: Iprops) => {
  const {selectedTab, onTabChange, data} = props;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    onTabChange(newValue)
  };

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Tabs
      value={selectedTab} onChange={handleChange}
      aria-label="tabs"
    >
      {
        data.map((data: any, index: number) => {
          return (
            <Tab label={data.text} {...a11yProps(data.index)} key={index}/>
          )
        })
      }
    </Tabs>
  );
}

export default CustomTab;
