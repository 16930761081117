import React from "react";
import IconLoader from "../../../components/IconLoader";
import Page from "../../../components/Page";
import TextWrapper from "../../../components/TextWrapper";
import theme from "../../../theme";
import IDOCountdown from "../../components/IDOCountdown";
import TiersDetailsCard from "../../components/TiersDetailsCard/TiersDetailsCard";
import {PeopleOfEden} from "../../config";
import {getStatus} from "../../utils/Status";

const TiersList = () => {
  const projectId = 'PeopleOfEden';
  const projectDetails = PeopleOfEden;

  const tiers = projectDetails.tiers;

  const startAndEndDate = {
    startDate: projectDetails.dates.startDate,
    endDate: projectDetails.dates.endDate,
  }

  const status = getStatus(startAndEndDate);

  return (
    <div>
      <Page>
        <div className={"m-b-20 single-line-center-start mo-custom-container"}>
          <a href={"/#/"}>
            <TextWrapper text={'Projects'} Fcolor={theme.color.transparent[200]}/>
          </a>
          <IconLoader
            iconName={'ArrowRight'}
            iconType={'arrow'}
            className={"m-r-4 m-l-4"}
          />
          <TextWrapper
            text={`${projectDetails.displayName} Pools`}
            Fcolor={theme.color.transparent[100]}
          />
        </div>
        <IDOCountdown projectDetails={projectDetails} projectId={projectId} status={status}/>
        {
          Object.entries(tiers).map(([id, details], index) => {
            return <TiersDetailsCard projectDetails={projectDetails} projectId={projectId} tiersDetails={details}
                                     tiersId={id} key={index}/>
          })
        }
      </Page>
    </div>
  )
}

export default TiersList;
