import React from "react";
import Modal from "../../components/Modal";
import TextButton from "../../components/TextButton";
import TextWrapper from "../../components/TextWrapper";

interface IProps {
  open: boolean;
  handleClose: () => void;
  msg?: string;
}

const FailureModal = (props: IProps) => {
  const {msg = "Oops! Something went wrong please try again"} = props;

  return (
    <div>
      <Modal open={props.open} handleClose={props.handleClose} closeButton title={`Error while Minting Citizenship`}>
        <TextWrapper text={msg} align={'center'} fontFamily={'Syne'} fontSize={16}/>
        {msg.includes('peopleofeden.com')
          &&
          <TextButton onClick={() => window.open('https://peopleofeden.com')} text={'peopleofeden.com'} align={'center'}
                      className={'m-t-32'}/>
        }
      </Modal>
    </div>

  )
}

export default FailureModal;
