import {chain} from "wagmi";
import {isProduction} from "./analytics/Mixpanel";
import ethereum from "./configs/ethereum";
import polygon from "./configs/polygon";
import {Configuration, ListOfProjects, PROJECTS} from "./utils/interface";

const configurations: { [env: string]: Configuration } = {
  ...ethereum,
  ...polygon
};

export let ConfigChains = [chain.mainnet, chain.polygon];

if (isProduction) {
  ConfigChains = [chain.mainnet]
}

export default configurations;

export const getSupportedChains = (): number[] =>
  Object.keys(configurations).map((i) => Number(i));

export const projects: PROJECTS = {
  'forward': 'FORWARD',
  'forwardmahax': 'FORWARD',
  'forwardmahax2': 'FORWARD',
  'forwardsquareup': 'FORWARD',
  'scallop': 'SCLP',
  'scallopmahax': 'SCLP',
};
export const listOfProjects: ListOfProjects = {
  'FORWARD': {
    projectName: ['forward', 'forwardmahax', 'forwardmahax2', 'forwardsquareup']
  },
  'SCLP': {
    projectName: ['scallop', 'scallopmahax']
  }
}
