const formatErrorMessage = (message: string): string => {
  message = message.toLowerCase();

  if (message.includes("has to be non Atomic swap")) return "The sale is atomic.";
  if (message.includes(`has to be finalized`)) return "The sale has to close for this action to complete.";
  if (message.includes("has to be open")) return `The sale has to start for this action to complete.`;
  if (message.includes("has to be pre-started")) return `Error occured: The sale has already started.`;
  if (message.includes("has to be funded")) return "The sale is not yet funded.";
  if (message.includes("totalRaise is bigger than minimum raise amount")) return "This action is not callable.";
  if (message.includes("totalRaise is less than minimum raise amount"))
    return "Error occured, Total Raised is less than Minimum Raise Amount.";
  if (message.includes("amount is less than tokens available"))
    return "There is not enough allocation left for the request to be completed.";
  if (message.includes("amount is bigger than minimum amount"))
    return "The requested allocation is smaller than the minimum allocation required.";
  if (message.includes("amount is smaller than maximum amount"))
    return "The requested allocation is bigger than the maximum allocation allowed.";
  if (message.includes("address has already passed the max amount of swap"))
    return "You have already crossed the maximum allocation allowed.";
  if (message.includes("erc20 transfer didn´t work")) return "Token Transfer Failed, Try again!";
  if (message.includes("user has to cover the cost of the swap in eth, use the cost function to determine"))
    return "Requested allocation and invested value differ. Try again!";
  if (message.includes("purchase is either 0 or finalized"))
    return "You have not invested or you have already claimed the investment.";
  if (message.includes("execution reverted: !started"))
    return "Hold on! We know that you are waiting for the mint, but it's not open yet. It'll open at exactly 3pm UTC";
  if (message.includes("execution reverted: mint over"))
    return "Oops! Seems like the freemint is over, but you can still mint a citizen from peopleofeden.com";
  if (message.includes("execution reverted: !whitelist"))
    return "Looks like you are not whitelisted, but you can still mint a citizen from peopleofeden.com";
  if (message.includes("execution reverted: minted"))
    return "Woops! You can only mint one citizen per wallet. If you still want to mint more citizens, you can visit peopleofeden.com";

  // Fail safes like overflows etc.;
  return "Error Occured, Please try again later.";
};

export default formatErrorMessage;
