import {BigNumber} from 'ethers';
import {
  ApplicationState,
  BasicBoolState,
  BasicState,
  BasicStateString,
  EthereumConfig,
  MinAndMaxAllocationState,
  MyPurchasesState,
  RedeemIDAndAmountState,
  StartAndEndDate,
  TransactionState
} from './interface';

export const DAY = 86400;
export const DAY_IN_MS = 86400000;
export const BNZERO = BigNumber.from(0);

export const LOADING_DEFAULT_BASIC_STATE: BasicState = {
  isLoading: true,
  value: BigNumber.from(0)
};

export const LOADING_DEFAULT_BASIC_STATE_STRING: BasicStateString = {
  isLoading: true,
  value: "",
};

export const LOADING_DEFAULT_BASICBOOL_STATE: BasicBoolState = {
  isLoading: true,
  value: false
};

export const NON_LOADING_DEFAULT_BASICBOOL_STATE: BasicBoolState = {
  isLoading: false,
  value: false
};


export const LOADING_DEFAULT_MYPURCHASES_STATE: MyPurchasesState = {
  isLoading: true,
  value: []
};

export const NON_LOADING_DEFAULT_MYPURCHASES_STATE: MyPurchasesState = {
  isLoading: true,
  value: []
};

export const LOADING_DEFAULT_DATE_STATE: StartAndEndDate = {
  isLoading: true,
  startDate: 0,
  endDate: 0
};

export const LOADING_DEFAULT_REDEEMIDANDAMOUNT_STATE: RedeemIDAndAmountState = {
  isLoading: true,
  id: BigNumber.from(0),
  amount: BigNumber.from(0)
};

export const NON_LOADING_DEFAULT_REDEEMIDANDAMOUNT_STATE: RedeemIDAndAmountState = {
  isLoading: false,
  id: BigNumber.from(0),
  amount: BigNumber.from(0)
};

export const LOADING_DEFAULT_ALLOCATION_STATE: MinAndMaxAllocationState = {
  isLoading: true,
  minAllocation: BigNumber.from(0),
  maxAllocation: BigNumber.from(0),
};

export const NON_LOADING_DEFAULT_ALLOCATION_STATE: MinAndMaxAllocationState = {
  isLoading: false,
  minAllocation: BigNumber.from(0),
  maxAllocation: BigNumber.from(0),
};

export const NON_LOADING_DEFAULT_DATE_STATE: StartAndEndDate = {
  isLoading: false,
  startDate: 0,
  endDate: 0
};

export const NON_LOADING_DEFAULT_BASIC_STATE: BasicState = {
  isLoading: false,
  value: BigNumber.from(0)
};

export const NON_LOADING_DEFAULT_BASIC_STATE_STRING: BasicStateString = {
  isLoading: false,
  value: "",
};

export const DEFAULT_ETHEREUM_CONFIG: EthereumConfig = {
  testing: false,
  autoGasMultiplier: 1.5,
  defaultConfirmations: 1,
  defaultGas: '6000000',
  defaultGasPrice: '1000000000000',
  ethereumNodeTimeout: 10000,
};

export const DECIMALS_18 = BigNumber.from(10).pow(18);

export const INITIAL_APP_STATE: ApplicationState = {
  blockNumber: {},
  popupList: [],
  walletModalOpen: false,
  settingsMenuOpen: false,
};

export const INITIAL_TRANSACTION_STATE: TransactionState = {};

export const MAX_UINT_256: BigNumber = BigNumber.from("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
export const noOp = () => {
};
