import React, {useMemo} from "react";
import ProgressBar from "../../../components/ProgressBar";
import TextWrapper from "../../../components/TextWrapper";
import useGetAvailableAndFilledSlots from "../../../hooks/state/poe/useGetAvailableAndFilledSlots";
import theme from "../../../theme";
import {IPeopleOfEdenDetails, ITiers} from "../../config";
import {getRegistrationStatus, getStatus} from "../../utils/Status";
import ClaimButton from "../ClaimButton";
import Price from "../Price";
import SpotsProgressBar from "../SpotsProgressBar/ProgressBar";
import TargetMint from "../TargetMint";

interface IProps {
  projectId: string;
  projectDetails: IPeopleOfEdenDetails;
  tierId: string;
  tierDetails: ITiers;
}

const RightClaimingDetails = (props: IProps) => {


  return (
    <div className={"material-primary m-b-24"}>
      <TargetMint value={props.tierDetails.targetMint}/>
      <Price value={props.projectDetails.price}/>
      <SpotsProgressBar tiersDetails={props.tierDetails}/>
      <ClaimButton projectId={props.projectId} projectDetails={props.projectDetails} tierId={props.tierId}
                   tierDetails={props.tierDetails}/>
    </div>
  )
}

export default RightClaimingDetails;
