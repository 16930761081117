import React, {useMemo} from "react";
import ProgressBar from "../../../components/ProgressBar";
import TextWrapper from "../../../components/TextWrapper";
import useGetAvailableAndFilledSlots from "../../../hooks/state/poe/useGetAvailableAndFilledSlots";
import theme from "../../../theme";
import {ITiers} from "../../config";

interface IProps {
  tiersDetails: ITiers;
}

const SpotsProgressBar = (props: IProps) => {
  const spotsData = useGetAvailableAndFilledSlots(props.tiersDetails);

  const percentage = useMemo(() => {
    if (spotsData.isLoading) return 0;
    if(spotsData.value.totalSpots.eq(0) || spotsData.value.mintCount.eq(0)) return 0
    return Number(spotsData.value.mintCount.mul(100).div(spotsData.value.totalSpots));
  },[spotsData.isLoading, spotsData.value.mintCount, spotsData.value.totalSpots]);

  return (
    <div>
      <div className={'single-line-center-between m-b-8'}>
        <TextWrapper text={'Spots filled'} Fcolor={theme.color.transparent[100]}/>
        <TextWrapper text={`${Number(spotsData.value.mintCount)}`}/>
      </div>
      <ProgressBar barColor={theme.color.green[300]} percent={percentage} width={'100%'}/>
      <div className={'single-line-center-between m-t-8'}>
        <TextWrapper text={'Available spots'} Fcolor={theme.color.transparent[100]}/>
        <TextWrapper text={`${Number(spotsData.value.availableSpots)}`}/>
      </div>
    </div>
  )
}

export default SpotsProgressBar;
