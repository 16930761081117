import styled from "styled-components";

import theme from "../../../theme";
import {getRegistrationStatus} from "../../utils/Status";

interface IProps {
  projectId: string;
  startDate: number;
  endDate: number;
  className?: string;
}

function ProjectStatusTag(props: IProps) {

  const startAndEndDate = {
    startDate: props.startDate,
    endDate: props.endDate,
  }

  const status = getRegistrationStatus(startAndEndDate);

  if (status === "Registration starting soon") {
    return (
      <Tag
        color={theme.color.yellow[500]}
        bg={theme.color.yellow[100]}
        className={props.className}
      >
        Registration starting soon
      </Tag>
    );
  } else if (status === "Registration dates will be announced soon") {
    return (
      <Tag
        color={theme.color.green[500]}
        bg={theme.color.green[100]}
        className={props.className}
      >
        Registration dates will be announced soon
      </Tag>
    );
  } else if (status === "Registration started") {
    return (
      <Tag
        color={theme.color.green[500]}
        bg={theme.color.green[100]}
        className={props.className}
      >
        Registration started
      </Tag>
    );
  } else {
    return (
      <Tag
        color={theme.color.red[500]}
        bg={theme.color.red[100]}
        className={props.className}
      >
        Registration closed
      </Tag>
    );
  }
}

export default ProjectStatusTag

export const Tag = styled.div<{ color: string; bg: string }>`
  padding: 4px 8px;
  width: max-content;
  border-radius: 60px;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  background: ${(props) => {
  return props.bg;
}};
  color: ${(props) => {
  return props.color;
}};
`;
