import React from "react";
import Confetti from "react-confetti";
import Loader from 'react-spinners/ScaleLoader';
import styled from "styled-components";
import placeholder from '../../assets/images/placeholder.png';
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import TextWrapper from "../../components/TextWrapper";
import useGetNFTDetailsFromAPI from "../../hooks/state/poe/useGetNFTDetailsFromAPI";

interface IProps {
  open: boolean;
  handleClose: () => void;
  nftid: string;
}

let msg = 'Here is my new MAHA NFT';

const SuccessfulMintModal = (props: IProps) => {
  // const data = useGetNFTDetailsFromAPI(Number(props.nftid));

  return (
    <div>
      <Modal open={props.open} handleClose={props.handleClose} closeButton title={`CITIZEN #${props.nftid}`}>
        <ConfettiDiv><Confetti
          recycle={true}
          onConfettiComplete={() => console.log('finished')}
          numberOfPieces={500}
          width={window.innerWidth}
        /></ConfettiDiv>
        <NFTImage>
         {/* {data.isLoading
            ? <Loader height={84} width={4} color={'white'}/>
            : <NFTImg className='mb16' src={data.value?.image || placeholder} alt="gif"/>
          }*/}
          <NFTImg className='mb16' src={' https://cdn.peopleofeden.com/generation/loading-animated.gif'} alt="gif"/>
        </NFTImage>
        <TextWrapper text={'Your NFT has been minted you would be able to view it on opensea post 5pm UTC'} className={'m-t-12'} align={'center'}/>
        <div className={'m-t-32'}>
          <Button
            text={'View on opensea'}
            tracking_id={'view-on-opensea'}
            tracking_params={{
              other: {
                nftId: props.nftid
              }
            }}
            onClick={() => {
              if (props.nftid) window.open(`https://opensea.io/assets/ethereum/0xbdd8f4daf71c2cb16cce7e54bb81ef3cfcf5aacb/${props.nftid}`)
              else window.open(`https://opensea.io/assets/ethereum/0xbdd8f4daf71c2cb16cce7e54bb81ef3cfcf5aacb`)
            }}
          />
        </div>
    </Modal>
    </div>
  )
}

export default SuccessfulMintModal;

const NFTImage = styled.div`
  max-width: 420px;
  min-height: 120px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 520px;
  border-radius: 12px;
`

const NFTImg = styled.img`
  max-width: 420px;
  min-height: 120px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 520px;
  border-radius: 12px;
`

const ConfettiDiv = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
`
