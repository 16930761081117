export const dark = {
  500: '#151414',
  400: '#1F1E1E',
  300: '#2A2827',
  200: '#363130',
  100: '#423B38',
}

export const light = {
  500: '#D9D5D3',
  400: '#E2DFDF',
  300: '#EBEAEA',
  200: '#F5F5F5',
  100: '#FAFAFA',
}

export const primary = {
  500: '#D74D26',
  400: '#F7653B',
  300: '#FF7F57',
  200: '#FFA981',
  100: '#FEE2D5',
}

export const red = {
  500: '#BA1E38',
  400: '#EF3050',
  300: '#FA4C69',
  200: '#FF9EAE',
  100: '#FFDCE2',
}

export const green = {
  500: '#178A50',
  400: '#11AF60',
  300: '#20C974',
  200: '#88E0B4',
  100: '#C4F7DD',
}

export const yellow = {
  500: '#B87503',
  400: '#F09700',
  300: '#FCB400',
  200: '#FFD66E',
  100: '#FFEAB6',
}

export const purple = {
  500: '#5427AE',
  400: '#682AE5',
  300: '#7A3CF6',
  200: '#BD9CFF',
  100: '#DFCFFF',
}

export const transparent = {
  500: '#FFFFFF08',
  400: '#FFFFFF16',
  300: '#FFFFFF32',
  200: '#FFFFFF64',
  100: '#FFFFFF88',
}

export const transparentog = 'transparent'

export const pink = {
  200: '#F47F57',
  300: '#FF7F57',
  400: '#FD5656'
}

export const grey = {
  100: '#f5f5f5',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
}

export const gradients = {
  brown_gradient: 'linear-gradient(180deg, #48423E 0%, #373030 100%)',
  orange_gradient: 'linear-gradient(38.44deg, #F47F57 15.81%, #FD5656 87.57%)',
  gray_linear: 'linear-gradient(180deg, #2A2827 0%, rgba(42, 40, 39, 0) 100%)',
  dark_linear: 'linear-gradient(180deg, #2D2D2D 0%, #1C1C1C 100%)',
}

export const black = '#000000'

export const white = '#FFFFFF'

export const teal = {
  200: '#64ffda'
}
