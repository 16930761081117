import React from "react";
import {Mixpanel} from "../../analytics/Mixpanel";
import '../styles/custom-bootstarp.css';

import '../styles/footer.css';

const Footer = () => {
  const trackingButtons = (id: string) => {
    Mixpanel.track(`buttonClick:${id}`)
  }

  return (
    <footer>
      <div className="container-fluid footer-content">
        <div className="footer-wrap row" data-aos="fade-up" data-aos-duration="2000" data-aos-once="true">
          <div className="row col-sm-8 col-md-8">
            <div className="col-sm-4 col-md-4 col-6">
              <div className="card-title footer-title typo-syne-h4">ARTH Coin</div>
              <ul className="footer-link typo-body-medium">
                <li>
                  <a
                    className="opacity-link"
                    href="https://arth.loans"
                    id={"footer_arth_loans"}
                    onClick={() => trackingButtons("footer_arth_loans")}
                  >
                    ARTHLoan
                  </a>
                </li>
                <li>
                  <a
                    className="opacity-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://arth.loans/farming"
                    id={"footer_Farm_Now"}
                    onClick={() => trackingButtons("footer_Farm_Now")}
                  >
                    Farms
                  </a>
                </li>
                <li>
                  <a
                    className="opacity-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://mahastarter.com/#/"
                    id={"footer_Maha_Starter"}
                    onClick={() => trackingButtons("footer_Maha_Starter")}
                  >
                    MahaStarter
                  </a>
                </li>
                {/*<li>
                  <a
                    className="opacity-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://mahachain.com/"
                    id={"footer_MAHA_Chain"}
                    onClick={() => trackingButtons("footer_MAHA_Chain")}
                  >
                    MahaChain
                  </a>
                </li>*/}
              </ul>
            </div>
            <div className="col-sm-4 col-md-4 col-6">
              <div className="card-title footer-title typo-syne-h4">DAO</div>
              <ul className="footer-link typo-body-medium">
                <li>
                  <a
                    className="opacity-link"
                    href="https://gov.mahadao.com/#/"
                    id={"footer_JoinDAO"}
                    onClick={() => trackingButtons("footer_JoinDAO")}
                  >
                    Join DAO
                  </a>
                </li>
                <li>
                  <a
                    className="opacity-link"
                    href="https://vote.mahadao.com/#/"
                    id={"footer_Vote"}
                    onClick={() => trackingButtons("footer_Vote")}
                  >
                    Vote
                  </a>
                </li>
                {/*<li>
                  <a
                    className="opacity-link"
                    rel="noreferrer"
                    target="_blank"
                    href="https://gov.mahadao.com/#/locker"
                    id={"footer_MAHAX"}
                    onClick={() => trackingButtons("footer_MAHAX")}
                  >
                    MAHAX
                  </a>
                </li>*/}
              </ul>
            </div>
            <div className="col-sm-4 col-md-4 col-6">
              <div className="card-title footer-title typo-syne-h4">Resources</div>
              <ul className="footer-link typo-body-medium">
                <li>
                  <a
                    className="opacity-link"
                    href="https://docs.mahadao.com"
                    id={"footer_Documentation"}
                    onClick={() => trackingButtons("footer_Documentation")}
                  >
                    Documentation
                  </a>
                </li>
                {/*<li>
                  <a
                    className="opacity-link"
                    href="https://mahadao.com/brandkit.html"
                    id={"footer_BrandKit"}
                    onClick={() => trackingButtons("footer_BrandKit")}
                  >
                    Brand kit
                  </a>
                </li>*/}
                <li>
                  <a
                    className="opacity-link"
                    href="https://docs.arth.loans/"
                    id={"footer_ARTH"}
                    onClick={() => trackingButtons("footer_ARTH")}
                  >
                    Learn ARTH
                  </a>
                </li>
              </ul>
            </div>

            {/*<div className="col-sm-4 col-md-4 col-6">
              <div
                className="card-title footer-title typo-syne-h4"
                id={"footer_About"}
                onClick={() => trackingButtons("footer_About")}
              >
                About
              </div>
              <ul className="footer-link typo-body-medium">
                <li>
                  <a
                    className="opacity-link"
                    href="https://mahadao.com/about.html"
                    id={"footer_Team"}
                    onClick={() => trackingButtons("footer_Team")}
                  >
                    Team
                  </a>
                </li>
                <li>
                  <a
                    className="opacity-link"
                    href="https://mahadao.com/about.html"
                    id={"footer_Partner"}
                    onClick={() => trackingButtons("footer_Partner")}
                  >
                    Partner
                  </a>
                </li>
                <li>
                  <a
                    className="opacity-link"
                    href="https://mahadao.com/careers.html"
                    id={"footer_Careers"}
                    onClick={() => trackingButtons("footer_Careers")}
                  >
                    Careers
                  </a>
                </li>
              </ul>
            </div>*/}

            {/*<div className="col-sm-4 col-md-4 col-6">
              <div
                className="card-title footer-title typo-syne-h4"
                id={"footer_Contact_us"}
                onClick={() => trackingButtons("footer_Contact_us")}
              >
                Contact us
              </div>
              <ul className="footer-link typo-body-medium">
                <li>
                  <a
                    className="opacity-link"
                    href="mailto:partners@mahadao.com"
                    id={"footer_Partnership"}
                    onClick={() => trackingButtons("footer_Partnership")}
                  >
                    Partnership
                  </a>
                </li>
                <li>
                  <a
                    className="opacity-link"
                    href="mailto:marketing@mahadao.com"
                    id={"footer_Marketing"}
                    onClick={() => trackingButtons("footer_Marketing")}
                  >
                    Marketing
                  </a>
                </li>
              </ul>
            </div>*/}
          </div>
          <div className="col-sm-4 col-md-4">
            <div
              className="card-title footer-title typo-syne-h4"
              id={"footer_Join_the_community"}
              onClick={() => trackingButtons("footer_Join_the_community")}
            >
              Join the community
            </div>
            <div className="footer-text typo-body-medium">
              Learn more about the MahaDAO, chat with the team
              and others in the community. Have your say in shaping
              the future of decentralized finance.
            </div>
            <div className="social-items">
              <div className="icon-row">
                <a id="social-twitter" rel="noreferrer" target="_blank" href="https://twitter.com/TheMahaDAO"
                   onClick={() => trackingButtons("social-twitter")}>
                  <button className="button3 ml-0">
                    <i className="fab fa-twitter"></i>
                  </button>
                </a>
                <a id="social-telegram" rel="noreferrer" target="_blank" href="https://t.me/MahaDAO"
                   onClick={() => trackingButtons("social-telegram")}>
                  <button className="button3">
                    <i className="fab fa-telegram-plane"></i>
                  </button>
                </a>
                <a id="social-medium" rel="noreferrer" target="_blank" href="https://medium.com/mahadao"
                   onClick={() => trackingButtons("social-medium")}>
                  <button className="button3">
                    <i className="fab fa-medium"></i>
                  </button>
                </a>
                <a id="social-reddit" rel="noreferrer" target="_blank" href="https://www.reddit.com/r/MahaDAO/"
                   onClick={() => trackingButtons("social-reddit")}>
                  <button className="button3">
                    <i className="fab fa-reddit"></i>
                  </button>
                </a>
                <a id="social-github" rel="noreferrer" target="_blank" href="https://github.com/mahadao"
                   onClick={() => trackingButtons("social-github")}>
                  <button className="button3">
                    <i className="fab fa-github"></i>
                  </button>
                </a>
              </div>
              <div className="icon-row">
                <a id="social-discord" rel="noreferrer" target="_blank" href="https://discord.gg/mahadao"
                   onClick={() => trackingButtons("social-discord")}>
                  <button className="button3">
                    <i className="fab fa-discord"></i>
                  </button>
                </a>
                <a id="social-comment" rel="noreferrer" target="_blank" href="https://discuss.mahadao.com/"
                   onClick={() => trackingButtons("social-comment")}>
                  <button className="button3">
                    <i className="fab fas fa-comment"></i>
                  </button>
                </a>
                <a id="social-facebook" rel="noreferrer" target="_blank" href="https://www.facebook.com/themahadao"
                   onClick={() => trackingButtons("social-facebook")}>
                  <button className="button3">
                    <i className="fab  fa-facebook-f"></i>
                  </button>
                </a>
                <a id="social-instagram" rel="noreferrer" target="_blank" href="https://www.instagram.com/themahadao/"
                   onClick={() => trackingButtons("social-instagram")}>
                  <button className="button3">
                    <i className="fab  fa-instagram"></i>
                  </button>
                </a>
                <a id="social-youtube" rel="noreferrer" target="_blank"
                   href="https://youtube.com/channel/UCvC4NADSkysiGiCgnP4dlmg"
                   onClick={() => trackingButtons("social-youtube")}>
                  <button className="button3">
                    <i className="fab  fa-youtube"></i>
                  </button>
                </a>

              </div>
              <div className="icon-row">
                <a id="social-linkedin" rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/mahadao"
                   onClick={() => trackingButtons("social-linkedin")}>
                  <button className="button3">
                    <i className="fab  fa-linkedin"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="white-line"></div>
        <div className="footer-wrap d-flex footer-btm typo-body-medium">
          <div className="single-line-center-between">
            <div className="single-line-center-start">
              Powered By
              <a rel="noreferrer" target="_blank" href="https://mahadao.com/"
                 onClick={() => trackingButtons("footer_MahaDAO")}>
                <span className="footer-logos" id="mahaFooter"/>
              </a>
            </div>
            <div className="ml-auto">
              <a id={"Privacy_Policy"} className="opacity-link" href="https://mahadao.com/privacy-policy.html"
                 onClick={() => trackingButtons("Privacy_Policy")}>Privacy Policy</a>
            </div>
          </div>
        </div>
        <div id="footer-gradient"></div>
      </div>
    </footer>
  );
}

export default Footer;
