import {BigNumber} from "ethers";
import {useCallback, useEffect, useState} from "react";
import {ITiers} from "../../../PeopleOfEden/config";
import {BNZERO, LOADING_DEFAULT_BASICBOOL_STATE, NON_LOADING_DEFAULT_BASICBOOL_STATE,} from "../../../utils/constants";
import {useGetAccount, useGetChainId} from "../../../utils/NetworksCustomHooks";
import useCore from "../../useCore";

interface IData {
  availableSpots: BigNumber,
  mintCount: BigNumber,
  totalSpots: BigNumber,
}

const data: IData = {
  availableSpots: BNZERO,
  mintCount: BNZERO,
  totalSpots: BNZERO,
}

interface IState {
  isLoading: boolean;
  value: IData;
}

const LoadingData: IState = {
  isLoading: true,
  value: {
    availableSpots: BNZERO,
    mintCount: BNZERO,
    totalSpots: BNZERO,
  }
}

const NonLoadingData: IState = {
  isLoading: false,
  value: {
    availableSpots: BNZERO,
    mintCount: BNZERO,
    totalSpots: BNZERO,
  }
}


const useGetAvailableAndFilledSlots = (tierDetails: ITiers) => {
  const [value, setValue] = useState<IState>(
    LoadingData
  );

  const core = useCore();
  const account = useGetAccount();
  const chainId = useGetChainId();

  const fetchData = useCallback(async () => {
    const contract = core.getClaimingContract(chainId, tierDetails.contract);

    if (!account || contract === undefined) {
      setValue(NonLoadingData);
      return;
    }

    const availableSpots: BigNumber = await contract.availableSpots();
    const mintCount: BigNumber = await contract.mintCount();
    const totalSpots = availableSpots.add(mintCount);

    setValue({
      isLoading: false,
      value: {
        availableSpots: availableSpots,
        mintCount: mintCount,
        totalSpots: totalSpots,
      }
    })

  }, [account, chainId, core, tierDetails.contract]);

  useEffect(() => {
    if (core) {
      fetchData().catch((err) => {
        setValue(NonLoadingData);
        console.error(`Failed to fetch spots status: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useGetAvailableAndFilledSlots;
