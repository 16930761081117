import styled, {keyframes} from "styled-components";

import rocketImg from '../../assets/images/rocket-dynamic-premium.png'
import Button from "../../components/Button";
import TextWrapper from "../../components/TextWrapper";
import theme from "../../theme";

const ApplyForLaunch = () => {
  return (
    <Main className="single-line-center-center">
      <div className="text-center">
        <RocketDiv>
          <Rocket src={rocketImg} alt={"rocketImg"} width={140} height={140} className="m-b-8"/>
        </RocketDiv>
        <TextWrapper
          text={"Submit your application to start raising funds on Mahastarter"}
          fontSize={18}
          className="m-b-20"
          align="center"
        />
        <ButtonContainer>
          <Button
            tracking_id={"apply_for_launch"}
            onClick={() => window.open("https://docs.google.com/forms/d/1S7UMa68CI6OEdOiHAdrzTHoIiv9k33oVtBl4maQQHNI/edit?ts=6177d64f")}
          >
            Apply for launch
          </Button>
        </ButtonContainer>
      </div>
    </Main>
  )
}

export default ApplyForLaunch;

const rocketAni = keyframes`
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(-20px, 20px);
  }
  100% {
    transform: translate(300px, -300px);
  }
`

const RocketDiv = styled.div`
  cursor: pointer;
`

const Rocket = styled.img`
  position: relative;
`

const Main = styled.div`
  padding: 85px 32px;
  height: 100%;
  border: 1px dashed ${theme.color.transparent[300]};
  border-radius: 12px;
  overflow: hidden;

  &:hover ${Rocket} {
    animation: ${rocketAni} 2s;
  }
`

const ButtonContainer = styled.div`
  margin: 0 auto;
  width: 172px;
  @media (max-width: 600px) {
    width: 100%;
  }
`






