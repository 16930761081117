import React, { useState } from "react";
import Button from "../../../components/Button";
import TextWrapper from "../../../components/TextWrapper";
import useMint from "../../../hooks/callbacks/poe/useMint";
import useGetIsMinted from "../../../hooks/state/poe/useGetIsMinted";
import theme from "../../../theme";
import { useGetAccount } from "../../../utils/NetworksCustomHooks";
import { IPeopleOfEdenDetails, ITiers } from "../../config";
import FailureModal from "../../modals/FailureModal";
import MintingModal from "../../modals/MintingModal";
import SuccessfulMintModal from "../../modals/SuccessfulMintModal";
import {getStatus} from "../../utils/Status";
import { useGetUsersProof } from "../../utils/useGetUsersProof";

interface IProps {
  projectId: string;
  projectDetails: IPeopleOfEdenDetails;
  tierId: string;
  tierDetails: ITiers;
}

export type ITxStatus = 'initial' | 'not-initiated' | 'in-progress' | 'successful' | 'not-successful' | '';

const ClaimButton = (props: IProps) => {
  const account = useGetAccount();
  const startAndEndDate = {
    startDate: props.tierDetails.mintDate.startDate,
    endDate: props.tierDetails.mintDate.endDate,
  }
  const status = getStatus(startAndEndDate);
  const isWhiteListed = useGetUsersProof(props.tierDetails.merkleProof, account);
  const [txStatus, setTxStatus] = useState<ITxStatus>('initial');
  const [failureMsg, setFailureMsg] = useState<string>('');
  const [NFTId, setNFTId] = useState<string>('');
  const hasMinted = useGetIsMinted(props.tierDetails);

  const MintAction = useMint(
    props.tierDetails,
    isWhiteListed
  );

  const handleMint = () => {
    MintAction(() => {
      setTxStatus('in-progress');
    },
      (nftId) => {
        setTxStatus('successful');
        setNFTId(nftId);
      },
      (msg: string) => {
        setTxStatus('not-successful');
        setFailureMsg(msg);
      }
    ).then(r => {
    });
  };

  return (
    <div className={'m-t-32'}>
      <SuccessfulMintModal open={txStatus === 'successful'} handleClose={() => {
        setTxStatus('initial');
        window.location.reload();
      }} nftid={NFTId} />
      <MintingModal open={txStatus === 'in-progress'} handleClose={() => setTxStatus('initial')} />
      <FailureModal open={txStatus === 'not-successful'} handleClose={() => setTxStatus('initial')}
        msg={failureMsg === '' ? undefined : failureMsg} />
      <div className={'m-b-4'}>
        {props.tierDetails.merkleProof.leaves.length > 0 && !hasMinted.value &&
          (isWhiteListed ?
            <TextWrapper text={'Congratulations! You have been whitelisted!'} Fcolor={theme.color.green[300]}
              align={'center'} />
            : <TextWrapper
              text={<div>Unfortunately, you are not whitelisted. But you can still get your citizen from <a
                className={'links'} href={'https://peopleofeden.com?openPopup'}>peopleofeden.com</a> &#127881;</div>}
              align={'center'}
            />)
        }
      </div>
      {
        hasMinted.value &&
        <TextWrapper
          text={<div>You have minted your <a href={'https://peopleofeden.com/#/account'}
            className={'links'}>citizen</a>. If you want to mint more you can visit <a
              href={'https://peopleofeden.com'}
              className={'links'}>peopleofeden.com</a></div>}
          align={'center'}
          className={'m-b-4'}
        />
      }
      <div className={'m-t-4'}>
        <Button
          disabled={isWhiteListed === null}
          tracking_id={'Mint'}
          text={status === 'closed'? 'Mint Ended': 'Mint'}
          onClick={handleMint}
        />
      </div>
    </div>
  )
}

export default ClaimButton;
