import styled from "styled-components";

import TextWrapper from "../../../../components/TextWrapper";

import theme from "../../../../theme";

import { IPeopleOfEdenDetails } from "../../../config";

interface IProps {
  projectId: string;
  projectDetails: IPeopleOfEdenDetails;
}

const ProjectOverview = (props: IProps) => {
  return (
    <div className="mo-custom-container">
      <div className="material-primary m-b-32">
        <TextWrapper
          text={"About this Initial NFT Offering (INO)"}
          fontSize={12}
          Fcolor={'#999'}
          className="m-b-8"
        />
        <TextWrapper
          text={" The People of Eden is first of its kind DeFi PFP collection combining art, robust utility, and storytelling to communicate the importance of financial freedom through an open yet decentralized ecosystem using $MAHA and $ARTH"}
          fontSize={18}
          fontWeight={600}
          className={"m-b-8"}
        />
        <div onClick={() => window.open("https://peopleofeden.com/")} className="pointer">
          <TextWrapper
            text={"Learn more about People Of Eden"}
            fontSize={12}
            Fcolor={theme.color.primary[300]}
            className="m-t-8"
          />
        </div>
      </div>
      <div className="m-b-32">
        <TextWrapper
          text={"Problem"}
          fontSize={16}
          fontWeight={600}
          className="m-b-12"
        />
        <TextWrapper
          text={"One of the issues with DeFi is the lack of engagement and adoption from the wider population. Through compelling art, storytelling, and utility, the People of Eden seeks to attract a broader audience and communicate the importance of financial freedom within a decentralized ecosystem."
          }
          fontSize={16}
          Fcolor={theme.color.transparent[100]}
          className={"m-b-8"}
        />
        {/* <RulesList>
          <li>The collection aims to educate and promote financial freedom through art, robust utility, and storytelling.</li>
          <li>The NFTs serve as a "citizenship ID" within the Eden ecosystem, providing exclusive utility and access to future drops by the IP.</li>
<li>Each NFT represents a staked $MAHA lock, which is the governance token of MahaDAO, making the NFT and $MAHA stake interconnected.</li>
<li>The collection offers four rarity levels based on the amount of $MAHA tokens locked within the NFT, which provides increased rarity and value.</li>
<li>Each citizen in the collection showcases diversity through a fusion of cultures and features, with 11 unique traits and over 400 handcrafted layers.</li>
<li>Holders can create their unique Web3 identity by choosing the gender and skin color of their NFT.</li>
<li>The People of Eden collection is the first-of-its-kind DeFi PFP collection that combines art, utility, and storytelling to promote financial freedom.</li>

        </RulesList> */}
      </div>
      <div className="m-b-32">
        <TextWrapper
          text={"Solution"}
          fontSize={16}
          fontWeight={600}
          className="m-b-12"
        />
        <TextWrapper
          text={"The People of Eden is a first-of-its-kind DeFi PFP collection. It combines art, storytelling, and robust utility to attract a broader audience."
          }
          fontSize={16}
          Fcolor={theme.color.transparent[100]}
          className="m-b-8"
        />
        <RulesList>
          <li>The collection aims to educate and promote financial freedom through art, robust utility, and storytelling.</li>
          <li>The NFTs serve as a "citizenship ID" within the Eden ecosystem, providing exclusive utility and access to future drops by the IP.</li>
          <li>Each NFT represents a staked $MAHA lock, which is the governance token of MahaDAO, making the NFT and $MAHA stake interconnected.</li>
          <li>The collection offers four rarity levels based on the amount of $MAHA tokens locked within the NFT, which provides increased rarity and value.</li>
          <li>Each citizen in the collection showcases diversity through a fusion of cultures and features, with 11 unique traits and over 400 handcrafted layers.</li>
          <li>Holders can create their unique Web3 identity by choosing the gender and skin color of their NFT.</li>
          <li>The People of Eden collection is the first-of-its-kind DeFi PFP collection that combines art, utility, and storytelling to promote financial freedom.</li>

        </RulesList>
      </div>
      <div className="m-b-32">
        <TextWrapper
          text={"People Of Eden Details"}
          fontSize={16}
          fontWeight={600}
          className="m-b-12"
        />
        <div onClick={() => window.open("https://peopleofeden.com")} className={"w-max-content"}>
          <TextWrapper
            text={"PeopleOfEden.com"}
            fontSize={16}
            Fcolor={theme.color.primary[300]}
            className="pointer m-b-4"
          />
        </div>
        <div onClick={() => window.open("https://opensea.io/collection/people-of-eden")}
          className={"w-max-content m-b-12"}>
          <TextWrapper
            text={"OpenSea"}
            fontSize={16}
            Fcolor={theme.color.primary[300]}
            className="pointer"
          />
        </div>
        <div className="single-line-center-start">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/ThePeopleOfEden"
          >
            <IconContainer>
              <Icon className="fab fa-twitter" />
            </IconContainer>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://discord.gg/mahadao"
          >
            <IconContainer>
              <Icon className="fab fa-discord" />
            </IconContainer>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProjectOverview;

const Icon = styled.i`
  color: #262626;
  font-size: 18px;
  margin: 0 50px 25px 0;
  position: absolute;
  top: 50%;
  left: 49%;
  transform: translate(-50%, -50%);
`;

const IconContainer = styled.button`
  border-radius: 45px;
  margin-right: 20px;
  text-align: left;
  justify-content: left;
  position: relative;
  border-style: none;
  width: 32px;
  height: 32px;
  background: ${theme.color.transparent[100]};
  transition: 0.5s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${theme.color.transparent[200]};
  }
`;

const RulesList = styled.ol`
  padding-left: 20px;
  list-style-type: disc;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 120%;
  color: ${theme.color.transparent[100]};
  margin-bottom: 32px;
`;
