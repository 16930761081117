import makeUrls, { TCalendarEvent } from "add-event-to-calendar";
import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import styled from "styled-components";
import google from '../../../assets/images/googleCalendar.svg'

import TextWrapper from "../../../components/TextWrapper";

import theme from "../../../theme";

import { IPeopleOfEdenDetails } from "../../config";

interface IProps {
  projectDetails: IPeopleOfEdenDetails;
  projectId: string;
  status: string;
}

const IDOCountdown = (props: IProps) => {
  const [calendarLink, setLink] = useState('');

  useEffect(() => {
    const onClick = () => {
      let event: TCalendarEvent = {
        name: `${props.projectId.toUpperCase()} NFT Free Mint`,
        location: `https://${window.location.hostname}/#/projects/${props.projectId.toLowerCase()}`,
        details: `Reminder for ${props.projectId.toUpperCase()} NFT Free Mint`,
        startsAt: new Date(props.projectDetails.dates.startDate * 1000).toUTCString(),
        endsAt: new Date(props.projectDetails.dates.endDate * 1000).toUTCString(),
      };
      setLink(makeUrls(event).google);
    };
    onClick();
  }, [props.projectId, props.projectDetails.dates]);

  if (props.status === 'closed') return <div />

  return (
    <div className="material-primary m-b-24">
      <TextWrapper
        text={props.status === 'upcoming' ? 'People of Eden free mint starts in' : 'People of Eden free mint ends in'}
        fontSize={16}
        Fcolor={theme.color.transparent[100]}
        align={"center"}
        className="m-b-8"
      />
      <div className="single-line-center-center m-b-16">
        {
          <Countdown
            date={
              props.status === "upcoming"
                ? props.projectDetails.dates.startDate * 1000
                : props.projectDetails.dates.endDate * 1000
            }
            renderer={({ days, hours, minutes, seconds }) => {
              return (
                <div className="single-line-center-between">
                  <div className="m-r-12">
                    <NumberContainer>
                      <TextWrapper
                        text={String(days).length === 1 ? `0${String(days)}` : String(days)}
                        fontSize={18}
                        fontWeight={600}
                      />
                    </NumberContainer>
                    <TextWrapper
                      text={"Days"}
                      fontSize={12}
                      Fcolor={theme.color.transparent[100]}
                      align={"center"}
                    />
                  </div>
                  <div style={{ marginBottom: "22px" }}>
                    <TextWrapper
                      text={":"}
                      fontSize={18}
                      fontWeight={600}
                      className="m-r-12"
                    />
                  </div>
                  <div className="m-r-12">
                    <NumberContainer>
                      <TextWrapper
                        text={String(hours).length === 1 ? `0${String(hours)}` : String(hours)}
                        fontSize={18}
                        fontWeight={600}
                      />
                    </NumberContainer>
                    <TextWrapper
                      text={"Hours"}
                      fontSize={12}
                      Fcolor={theme.color.transparent[100]}
                      align={"center"}
                    />
                  </div>
                  <div style={{ marginBottom: "22px" }}>
                    <TextWrapper
                      text={":"}
                      fontSize={18}
                      fontWeight={600}
                      className="m-r-12"
                    />
                  </div>
                  <div className="m-r-12">
                    <NumberContainer>
                      <TextWrapper
                        text={String(minutes).length === 1 ? `0${String(minutes)}` : String(minutes)}
                        fontSize={18}
                        fontWeight={600}
                      />
                    </NumberContainer>
                    <TextWrapper
                      text={"Minutes"}
                      fontSize={12}
                      Fcolor={theme.color.transparent[100]}
                      align={"center"}
                    />
                  </div>
                  <div style={{ marginBottom: "22px" }}>
                    <TextWrapper
                      text={":"}
                      fontSize={18}
                      fontWeight={600}
                      className="m-r-12"
                    />
                  </div>
                  <div>
                    <NumberContainer>
                      <TextWrapper
                        text={String(seconds).length === 1 ? `0${String(seconds)}` : String(seconds)}
                        fontSize={18}
                        fontWeight={600}
                      />
                    </NumberContainer>
                    <TextWrapper
                      text={"Seconds"}
                      fontSize={12}
                      Fcolor={theme.color.transparent[100]}
                      align={"center"}
                    />
                  </div>
                </div>
              )
            }}
          />
        }
      </div>
      {/*{
        calendarLink && props.status !== "closed" && (
          <HeaderButton onClick={() => window.open(calendarLink, '_blank')}>
            <img src={google} alt="calendar" height={24} />
            <span style={{ marginLeft: 8 }}>Add to Calendar</span>
          </HeaderButton>
        )
      }*/}
    </div>
  );
}

export default IDOCountdown;

const NumberContainer = styled.div`
  background: rgba(255, 255, 255, 0.08);
  border-radius: 6px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
`;

const HeaderButton = styled.div`
  background: rgba(97, 134, 242, 0.32);
  border-radius: 8px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.88);
  display: flex;
  flex-direction: row;
  padding: 15px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
`;
