import {Grid} from "@material-ui/core";

import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {isMobileGlobal} from "../../../App";
import IconLoader from "../../../components/IconLoader";
import Page from "../../../components/Page";
import Tab from "../../../components/Tab";

import TextWrapper from "../../../components/TextWrapper";

import theme from "../../../theme";
import LaunchingOn from "../../components/LaunchingOn";
import ListingOn from "../../components/ListingOn";
import ProjectInfoTabContent from "../../components/ProjectInfoTab/ProjectInfoTabContent";
import ProjectStatsSection from "../../components/ProjectStatsSection";
import ProjectCardStatusTag from "../../components/ProjectStatusTag";
import RegistrationStatusTag from "../../components/RegistrationStatusTag";
import {PeopleOfEden} from "../../config";


interface IProps {
  tiersId: string;
}

const Details = (props: IProps) => {
  const projectId = 'PeopleOfEden';
  const projectDetails = PeopleOfEden;
  const tiersId = props.tiersId;
  const tiersDetails = projectDetails.tiers[tiersId];
  const [selectedTab, setSelectedTab] = useState<0 | 1 | 2 | number>(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tabs = [
    {
      index: 0,
      key: 'Overview',
      text: 'Overview',
    },
    /* {
       index: 1,
       key: 'Timeline',
       text: 'IBO Timeline',
     },*/
  ];

  return (
    <Page>
      <div className={"custom-container"}>
        <div className={"m-b-20 single-line-center-start mo-custom-container"}>
          <a href={"/#/"}>
            <TextWrapper text={'Projects'} Fcolor={theme.color.transparent[200]}/>
          </a>
          <IconLoader
            iconName={'ArrowRight'}
            iconType={'arrow'}
            className={"m-r-4 m-l-4"}
          />
          <TextWrapper
            text={tiersDetails.displayName}
            Fcolor={theme.color.transparent[100]}
          />
        </div>
        <Grid container spacing={3}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <section className={"m-b-32 mo-custom-container"}>
              <div className="m-b-16">
                <div className="single-line-center-start">
                  <img src={projectDetails.logo} width={64} height={64} className={'rounded-50 m-r-24'}
                       alt={projectDetails.displayName}/>
                  <div>
                    <TextWrapper
                      text={tiersDetails.displayName}
                      fontSize={32}
                      fontWeight={"bold"}
                      className="m-b-8"
                    />
                    <ProjectCardStatusTag projectId={projectId} startDate={tiersDetails.mintDate.startDate}
                                          endDate={tiersDetails.mintDate.endDate}/>
                  </div>
                </div>
              </div>
              <div>
                <TextWrapper
                  text={tiersDetails?.tiersDesc? tiersDetails?.tiersDesc: projectDetails.desc}
                  fontSize={16}
                  Fcolor={theme.color.transparent[100]}
                  className={"m-b-16"}
                  lineHeight={'24px'}
                />
                <div className="single-line-center-between">
                  <div className="single-line-center-start">
                    <RegistrationStatusTag projectId={projectId} startDate={tiersDetails.registrationDates.startDate}
                                           endDate={tiersDetails.registrationDates.endDate}/>
                    <div className={'single-line-center-start'}>
                      <LaunchingOn value={projectDetails.chain}/>
                      {projectDetails.listingOn.map((data, index) => {
                        return <ListingOn value={data} key={index}/>
                      })}
                    </div>
                  </div>
                  <div className={"single-line-center-end"}>
                  </div>
                </div>
              </div>
            </section>
            <ProjectVideo
              width="100%"
              height="404"
              src={projectDetails.videoLink}
              frameBorder="0"
              allow="accelerometer; autoplay;"
              allowFullScreen
            >
            </ProjectVideo>
            {
              isMobileGlobal &&
              <div className="m-b-24">
                <ProjectStatsSection projectId={projectId} projectDetails={projectDetails} tierId={tiersId}
                                     tierDetails={tiersDetails}/>
              </div>
            }
            <section
              className={"w-100"}
              id={"tabs"}>
              <div className={"mo-custom-container"}>
                <Tab
                  selectedTab={selectedTab}
                  onTabChange={(d) => {
                    setSelectedTab(d);
                  }}
                  data={tabs}
                />
              </div>
              <div className="p-t-32">
                <ProjectInfoTabContent selectedTab={selectedTab} projectDetails={projectDetails} projectId={projectId}/>
              </div>
            </section>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            {!isMobileGlobal &&
              <ProjectStatsSection projectId={projectId} projectDetails={projectDetails} tierId={tiersId}
                                   tierDetails={tiersDetails}/>}
          </Grid>
        </Grid>
      </div>
    </Page>
  );
}

export default Details;

const ProjectVideo = styled.iframe`
  width: 100%;
  height: 404px;
  margin-bottom: 24px;
  @media (max-width: 600px){
    height: 207px;
  }
`;
