import React from "react";
import IconLoader from "../../../components/IconLoader";
import MaterialToolTip from "../../../components/MaterialToolTip";

interface IProps {
  value: string;
}

const LaunchingOn = (props: IProps) => {
  return (
    <MaterialToolTip
      toolTipContent={`Launching on ${props.value} chain`}
    >
      <IconLoader
        iconName={props.value}
        iconType={"chains"}
        width={24}
        className={"m-l-12"}
      />
    </MaterialToolTip>
  )
}

export default LaunchingOn;
