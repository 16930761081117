import React from "react";
import {IPeopleOfEdenDetails, ITiers} from "../../config";
import Price from "../Price";
import RegisterButton from "../RegisterButton";
import TargetMint from "../TargetMint";

interface IProps {
  projectId: string;
  projectDetails: IPeopleOfEdenDetails;
  tierId: string;
  tierDetails: ITiers;
}

const RightRegistrationDetails = (props: IProps) => {
  return (
    <div className={"material-primary m-b-24"}>
      <TargetMint value={props.tierDetails.targetMint}/>
      <Price value={props.projectDetails.price}/>
      <RegisterButton projectId={props.projectId} projectDetails={props.projectDetails} tierId={props.tierId}
                      tierDetails={props.tierDetails}/>
    </div>
  )
}

export default RightRegistrationDetails;
