import {Grid} from "@material-ui/core";
import React, {useEffect} from "react";
import styled from "styled-components";
import {isMobileGlobal} from "../../../App";

import Divider from "../../../components/Divider";
import TextWrapper from "../../../components/TextWrapper";

import theme from "../../../theme";
import {IPeopleOfEdenDetails, ITiers} from "../../config";
import LaunchingOn from "../LaunchingOn";
import ListingOn from "../ListingOn";
import Price from "../Price";
import ProjectCardStatusTag from "../ProjectStatusTag";
import RegistrationStatusTag from "../RegistrationStatusTag";
import ProgressBar from "../SpotsProgressBar/ProgressBar";

import TargetMint from "../TargetMint";

interface IProps {
  projectDetails: IPeopleOfEdenDetails;
  projectId: string;
  tiersDetails: ITiers;
  tiersId: string;
}

const TiersDetailsCard = (props: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onProjectClick = () => window.location.href = `/#/project/${props.projectDetails.projectUrlKey}/${props.tiersId}`;
  // const onProjectClick = () => {};

  return (
    <Card
      className="material-quaternary w-100 pointer"
      onClick={onProjectClick}
    >
      <Grid container spacing={2} alignItems={"flex-start"}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className="single-line-center-start m-b-12">
            <img src={props.projectDetails.logo} width={64} height={64} className={'rounded-50 m-r-24'}
                 alt={props.projectDetails.displayName}/>
            <div className={'single-line-center-start mo-single-line-column'}>
              <TextWrapper
                text={props.tiersDetails.displayName.toUpperCase()}
                fontSize={24}
                fontWeight={600}
                lineHeight={'32px'}
                className="m-r-8"
              />
              <ProjectCardStatusTag projectId={props.projectId} startDate={props.tiersDetails.mintDate.startDate}
                                    endDate={props.tiersDetails.mintDate.endDate}/>
            </div>
          </div>
          <div className={"m-b-24"}>
            <TextWrapper
              text={props.tiersDetails?.tiersDesc? props.tiersDetails?.tiersDesc: props.projectDetails.desc}
              lineHeight={'24px'}
              fontSize={16}
              Fcolor={theme.color.transparent[100]}
            />
          </div>
          <div className="single-line-center-between">
            <div className="single-line-center-start">
              <div>
                <RegistrationStatusTag projectId={props.projectId}
                                       startDate={props.tiersDetails.registrationDates.startDate}
                                       endDate={props.tiersDetails.registrationDates.endDate}/>
              </div>
              <div className={'single-line-center-end'}>
                <LaunchingOn value={props.projectDetails.chain}/>
                {props.projectDetails.listingOn.map((data, number) => {
                  return <ListingOn value={data} key={number}/>
                })}
              </div>
            </div>
          </div>
        </Grid>
        {isMobileGlobal && <Divider/>}
        <Grid item lg={6} md={6} sm={12} xs={12} style={{alignSelf: 'stretch'}}>
          <MobileCard>
            <TargetMint value={props.tiersDetails.targetMint}/>
            <Price value={props.projectDetails.price}/>
            <ProgressBar tiersDetails={props.tiersDetails}/>
          </MobileCard>
        </Grid>
      </Grid>
    </Card>
  );
}

export default TiersDetailsCard;

const Card = styled.div`
  transition: 0.5s ease-in-out;
  margin-bottom: 24px;
  @media (max-width: 600px) {
    border-radius: 0;
    padding: 32px 26px;
  }
`;

export const Tag = styled.div<{ color: string; bg: string }>`
  padding: 4px 8px;
  border-radius: 60px;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  background: ${(props) => {
  return props.bg;
}};
  color: ${(props) => {
  return props.color;
}};
`;

const MobileCard = styled.div`
  border-radius: 6px;
  background: ${theme.color.transparent[500]};
  padding: 20px;
  height: 100%;
  @media (max-width: 600px) {
    border-radius: 0;
    background: transparent;
    padding: 0;
  }
`;
