import styled, {keyframes} from "styled-components";
import bg1img from '../../assets/images/Ellipse.svg';
import starbg from '../../assets/images/StarsBG.svg';

import stick from '../../assets/images/sticks.svg';

import {isMobileGlobal} from "../../App";
import TextWrapper from "../../components/TextWrapper";

import theme from "../../theme";

const HeroSection = () => {
  return (
    <Contain>
      <Content className={"single-line-center-center animate__animated animate__fadeIn animate__slower"}>
        <MainSection>
          <TextWrapper
            fontFamily={"Syne"}
            text={"A Launchpad For The Decentralized Economy"}
            fontSize={isMobileGlobal ? 32 : 44}
            fontWeight={600}
            className="m-b-16"
            align={"center"}
          />
          <TextWrapper
            text={"MahaStarter is the first incubator for the MahaDAO Ecosystem. A launchpad that is bot-free, built for the people and focuses on  " +
              " high-potential projects."}
            fontSize={isMobileGlobal ? 16 : 18}
            className={"m-b-12"}
            lineHeight={"120%"}
            Fcolor={theme.color.transparent[100]}
            align={"center"}
          />
          <TextWrapper
            text={"All MAHA stakers will get incubated project's tokens and special access to the" +
              " IDO/IBOs. Incubated projects focus on growth and adoption of the $ARTH valuecoin."}
            fontSize={isMobileGlobal ? 16 : 18}
            className={"m-b-32"}
            lineHeight={"120%"}
            Fcolor={theme.color.transparent[100]}
            align={"center"}
          />
          {/*<div className="single-line-center-center">
            <div onClick={() => {
              Mixpanel.track(`buttonClick:mahastarter_works`)
              window.open("https://medium.com/mahadao/mahastarter-step-by-step-guidebook-to-participating-in-the-ibo-8d82dd9161f6")
            }} className="pointer">
              <TextWrapper
                text={"Learn how Mahastarter works"}
                fontSize={12}
                Fcolor={theme.color.primary[300]}
                className="m-t-8"
              />
            </div>
          </div>*/}
        </MainSection>
      </Content>
      {!isMobileGlobal && <div>
        <Bg1>
          <img src={bg1img} alt={'faded'}/>
        </Bg1>
        <Bg2>
          <img src={bg1img} alt={'faded'}/>
        </Bg2>
      </div>}
      <div>
        <Stick1>
          <img src={stick} alt={'faded'}/>
        </Stick1>
        <Stick2>
          <img src={stick} alt={'faded'}/>
        </Stick2>
        <Stick3>
          <img src={stick} alt={'faded'}/>
        </Stick3>
      </div>
      {!isMobileGlobal && <div>
        <Stick1O>
          <img src={stick} alt={'faded'}/>
        </Stick1O>
        <Stick2O>
          <img src={stick} alt={'faded'}/>
        </Stick2O>
        <Stick3O>
          <img src={stick} alt={'faded'}/>
        </Stick3O>
      </div>}
    </Contain>
  );
}

export default HeroSection;

const Contain = styled.div`
  height: 100vh;
  background: url(${starbg});
  @media (max-width: 600px) {
    //padding-top: 173px;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 600px) {
    width: auto;
  }
`;

const MainSection = styled.div`
  max-width: 800px;
  @media (max-width: 600px) {
    padding: 0 16px ;
    width: 100%;
  }
`;

const Bg1 = styled.div`
  position: absolute;
  top: calc(-50% - 180px);
  right: -50%;
  z-index: -1;
  opacity: 0.5;
`;


const Bg2 = styled.div`
  position: absolute;
  left: -50%;
  top: 30vh;
  opacity: 0.5;
  z-index: -1;
  transform: scale(0.8);
`;

const stick1Animation = keyframes`
  0% {
    top: 100vh;
    left: 80vw;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0;
    left: 100vw;
    opacity: 0;
  }
`;

const Stick1 = styled.div`
  position: absolute;
  top: 100vh;
  left: 80vw;
  transform: scale(0.8);
  animation: ${stick1Animation} 5s infinite;
  z-index: -1;
  opacity: 0;
  @media (max-width: 600px) {
    animation: none;
    top: 50px;
    right: 150px;
    transform: scale(0.5);
  }
`;

const stick2Animation = keyframes`
  0% {
    top: 100vh;
    left: 20vw;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0;
    left: 50vw;
    opacity: 0;
  }
`;

const Stick2 = styled.div`
  position: absolute;
  top: 100vh;
  left: 20vw;
  transform: scale(0.4);
  animation: ${stick2Animation} 6s infinite;
  z-index: -1;
  opacity: 0;
  @media (max-width: 600px) {
    animation: none;
    top: 50vh;
    left: 80px;
    transform: scale(0.3);
  }
`;

const stick3Animation = keyframes`
  0% {
    top: 100vh;
    left: -10vw;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0;
    left: 20vw;
    opacity: 0;
  }
`;

const Stick3 = styled.div`
  position: absolute;
  top: 100vh;
  left: -10vw;
  transform: scale(0.8);
  animation: ${stick3Animation} 5s infinite;
  opacity: 0;
  @media (max-width: 600px) {
    animation: none;
    top: 20vh;
    left: 10vw;
    transform: scale(0.3);
  }
`;

const stick1OAnimation = keyframes`
  0% {
    top: 105vh;
    left: 50vw;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0vh;
    left: 80vw;

    opacity: 0;
  }
`;

const Stick1O = styled.div`
  position: absolute;
  top: 105vh;
  left: 50vw;
  transform: scale(0.8);
  animation: ${stick1OAnimation} 5s infinite;
  animation-delay: 4s;
  z-index: -1;
  opacity: 0;
`;

const stick2OAnimation = keyframes`
  0% {
    top: 105vh;
    left: 30vw;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0vh;
    left: 60vw;

    opacity: 0;
  }
`;

const Stick2O = styled.div`
  position: absolute;
  top: 105vh;
  left: 30vw;
  transform: scale(0.4);
  animation: ${stick2OAnimation} 6s infinite;
  animation-delay: 2s;
  z-index: -1;
  opacity: 0;
`;

const stick3OAnimation = keyframes`
  0% {
    top: 105vh;
    left: 0vw;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0vh;
    left: 30vw;
    opacity: 0;
  }
`;

const Stick3O = styled.div`
  position: absolute;
  top: 105vh;
  left: 0vw;
  transform: scale(0.8);
  animation: ${stick3OAnimation} 8s infinite;
  z-index: -1;
  animation-delay: 4s;
  opacity: 0;
`;
