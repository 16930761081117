import React from "react";
import styled from "styled-components";
import placeholder from '../../assets/gifs/Minting-1.gif';
import Modal from "../../components/Modal";
import TextWrapper from "../../components/TextWrapper";

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const MintingModal = (props: IProps) => {
  return (
    <div>
      <Modal open={props.open} handleClose={props.handleClose} closeButton title={`Minting Citizenship`}>
        <NFTImage>
          <NFTImg className='mb16' src={placeholder} alt="gif"/>
        </NFTImage>
        <TextWrapper text={'Take a deep breath we are minting your citizenship'} align={'center'} fontFamily={'Syne'} className={'m-t-12'} fontSize={16}/>
      </Modal>
    </div>

  )
}

export default MintingModal;

const NFTImage = styled.div`
  max-width: 420px;
  min-height: 120px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 520px;
  border-radius: 12px;
`

const NFTImg = styled.img`
  max-width: 420px;
  min-height: 120px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 520px;
  border-radius: 12px;
`

const ConfettiDiv = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
`
