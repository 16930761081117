import {IPeopleOfEdenDetails, ITiers} from "../../config";
import {getRegistrationStatus, getStatus} from "../../utils/Status";
import IDOCountdown from "../IDOCountdown";
import RightClaimingDetails from "../RightClaimingDetails";
import RightRegistrationDetails from "../RightRegistrationDetails";

interface IProps {
  projectId: string;
  projectDetails: IPeopleOfEdenDetails;
  tierId: string;
  tierDetails: ITiers;
}

const ProjectStatsSection = (props: IProps) => {

  const startAndEndDate = {
    startDate: props.tierDetails.mintDate.startDate,
    endDate: props.tierDetails.mintDate.endDate,
  }

  /*  const tiersStartAndEndDate = {
      startDate: props.tierDetails.registrationDates.startDate,
      endDate: props.tierDetails.registrationDates.endDate,
    }*/

  const status = getStatus(startAndEndDate);

  const registrationStatus = getRegistrationStatus({
    startDate: props.tierDetails.registrationDates.startDate,
    endDate: props.tierDetails.registrationDates.endDate
  });

  return (
    <div>
      <IDOCountdown
        status={status}
        projectId={props.projectId}
        projectDetails={props.projectDetails}
      />
      {registrationStatus !== "Registration closed" && status !== 'live'
        ? <RightRegistrationDetails projectId={props.projectId} projectDetails={props.projectDetails}
                                    tierId={props.tierId}
                                    tierDetails={props.tierDetails}/>
        : <RightClaimingDetails projectId={props.projectId} projectDetails={props.projectDetails} tierId={props.tierId}
                                tierDetails={props.tierDetails}/>
      }
    </div>
  );
}

export default ProjectStatsSection;
