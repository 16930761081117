import React from "react";
import poeLogo from "../assets/icons/products/POE.png";

export interface IMerkleProof {
  root: string;
  leaves: {
    data: {
      address: string;
    };
    proof: string[];
  }[];
}

export interface ITiers {
  displayName: string;
  targetMint: number;
  checkEligibility: boolean;
  registerLink: string;
  tiersDesc?: React.ReactNode;
  registrationDates: {
    startDate: number;
    endDate: number;
  };
  mintDate: {
    startDate: number;
    endDate: number;
  };
  merkleProof: IMerkleProof;
  contract: string;
}

export interface IPeopleOfEdenDetails {
  network: string;
  projectUrlKey: string;
  displayName: string;
  logo: string;
  desc: string;
  chain: string;
  videoLink: string;
  dates: {
    startDate: number;
    endDate: number;
  };
  registrationDates: {
    startDate: number;
    endDate: number;
  };
  tiers: { [name: string]: ITiers };
  targetMint: number;
  price: number;
  listingOn: Array<string>;
}

export const PeopleOfEden: IPeopleOfEdenDetails = {
  network: "Ethereum",
  projectUrlKey: "people-of-eden",
  displayName: "People Of Eden",
  logo: poeLogo,
  chain: "Ethereum",
  listingOn: ["OpenSea"],
  desc: " The People of Eden is first of its kind DeFi PFP collection combining art, robust utility, and storytelling to communicate the importance of financial freedom through an open yet decentralized ecosystem using $MAHA and $ARTH.",
  dates: {
    startDate: new Date("March 9, 2023 15:00:00 UTC").getTime() / 1000,
    endDate: new Date("March 16, 2023 15:00:00 UTC").getTime() / 1000,
  },
  registrationDates: {
    startDate: new Date("Feb 14, 2023 15:00:00 UTC").getTime() / 1000,
    endDate: new Date("Mar 09, 2023 00:00:00 UTC").getTime() / 1000,
  },
  videoLink: "https://www.youtube.com/embed/CfPEP1DIE_8",
  targetMint: 1000,
  price: 0,
  tiers: {
    "mahax-holders": {
      displayName: "MAHAX holders",
      targetMint: 110,
      checkEligibility: false,
      registerLink: "",
      tiersDesc: 'This is a guaranteed whitelist mint for MAHA stakers. If you participated in our MAHAX alphabot raffle, your wallet will be whitelisted here. This mint will be open for 7 days after which any pending MAHA will be sent back to the treasury.',
      registrationDates: {
        startDate: new Date("Feb 20, 2023 15:00:00 UTC").getTime() / 1000,
        endDate: new Date("Feb 23, 2023 15:00:00 UTC").getTime() / 1000,
      },
      mintDate: {
        startDate: new Date("March 9, 2023 15:00:00 UTC").getTime() / 1000,
        endDate: new Date("March 16, 2023 15:00:00 UTC").getTime() / 1000,
      },
      merkleProof: require("./merkleProofs/merkleProof-mahax.json"),
      contract: "MAHAXMintProxy-mahax",
    },
    "community-mint": {
      displayName: "Community Mint",
      targetMint: 550,
      checkEligibility: true,
      tiersDesc: 'This is a guaranteed whitelist mint for exclusive community members. This mint will be open for 48 hours after any pending MAHA will be sent back to the treasury.',
      registerLink: "https://www.premint.xyz/People-Of-Eden-FREEMINT/",
      registrationDates: {
        startDate: new Date("March 1, 2023 15:00:00 UTC").getTime() / 1000,
        endDate: new Date("March 4, 2023 00:00:00 UTC").getTime() / 1000,
      },
      mintDate: {
        startDate: new Date("March 9, 2023 15:00:00 UTC").getTime() / 1000,
        endDate: new Date("March 12, 2023 15:00:00 UTC").getTime() / 1000,
      },
      merkleProof: require("./merkleProofs/merkleProof-community.json"),
      contract: "MAHAXMintProxy-community",
    },
    "public-mint": {
      displayName: "Public Mint",
      targetMint: 300,
      tiersDesc: 'This is a first-come first-serve whitelist. If you participated in our Premint campaign you will find that your wallet will be whitelisted here. Please note that this whitelist has been oversubcribed. If you don\'t get a chance to mint an NFT, you can still mint one by visiting peopleofeden.com',
      checkEligibility: true,
      registerLink: "https://www.premint.xyz/People-Of-Eden-FREEMINT/",
      registrationDates: {
        startDate: new Date("March 1, 2023 15:00:00 UTC").getTime() / 1000,
        endDate: new Date("March 4, 2023 00:00:00 UTC").getTime() / 1000,
      },
      mintDate: {
        startDate: new Date("March 9, 2023 15:00:00 UTC").getTime() / 1000,
        endDate: new Date("March 12, 2023 15:00:00 UTC").getTime() / 1000,
      },
      merkleProof: require("./merkleProofs/merkleProof-public.json"),
      contract: "MAHAXMintProxy-public",
    },
    "super-early-bird": {
      displayName: "Super Early Bird",
      targetMint: 50,
      checkEligibility: false,
      tiersDesc: 'This is a guaranteed whitelist mint for early supporters. If you participated in our early bird alphabot raffle, your wallet will be whitelisted here. This mint will be open for 48 hours after any pending MAHA will be sent back to the treasury.',
      registerLink: "",
      registrationDates: {
        startDate: new Date("Feb 15, 2023 15:00:00 UTC").getTime() / 1000,
        endDate: new Date("Feb 18, 2023 15:00:00 UTC").getTime() / 1000,
      },
      mintDate: {
        startDate: new Date("March 9, 2023 15:00:00 UTC").getTime() / 1000,
        endDate: new Date("March 12, 2023 15:00:00 UTC").getTime() / 1000,
      },
      merkleProof: require("./merkleProofs/merkleProof-early.json"),
      contract: "MAHAXMintProxy-early",
    },
  },
};
