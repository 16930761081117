import {IPeopleOfEdenDetails} from "../../config";
import ProjectOverview from "./components/ProjectOverview";

interface IProps {
  selectedTab: number;
  projectId: string;
  projectDetails: IPeopleOfEdenDetails;
}

const ProjectInfoTabContent = (props: IProps) => {

  // if (selectedTab === 1) return <ProjectTimeline id={id} />;
  // if (selectedTab === 2) return <ProjectVesting />;

  return <ProjectOverview projectId={props.projectId} projectDetails={props.projectDetails}/>;
}

export default ProjectInfoTabContent;
