import DataField from "../../../components/DataField";
import theme from "../../../theme";

interface IProps {
  value: number;
}

const Price = (props: IProps) => {
  return (
    <div className="m-b-16">
      <DataField
        label={"Price"}
        isValueLoading={false}
        value={props.value === 0 ? 'Free' : props.value.toLocaleString('en-us')}
        labelFontSize={18}
        labelFontColor={theme.color.transparent[100]}
        valueFontSize={18}
        valueFontColor={"white"}
        valueFontWeight={600}
      />
    </div>
  )
}

export default Price;
