import {Grid} from "@material-ui/core";
import React, {useEffect, useMemo} from "react";
import styled from "styled-components";
import DataField from "../../components/DataField";
import Divider from "../../components/Divider";

import IconLoader from "../../components/IconLoader";
import MaterialToolTip from "../../components/MaterialToolTip";
import TextWrapper from "../../components/TextWrapper";

import theme from "../../theme";
import {IProjectIdsArrayProp, ITokenSymbolProp} from "../../utils/interface";

interface ProjectCardProps {
  arrayOfIds: any[];
}

const ProjectCard = (props: ITokenSymbolProp & IProjectIdsArrayProp & ProjectCardProps) => {
  const {tokenSymbol} = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onProjectClick = () => window.location.href = `https://bsc.mahastarter.com/#/projectlist/${tokenSymbol}`;

  const targetRaiseDollar: any = {
    'SCLP': '$212k',
    'FORWARD': '$100k'
  }

  const targetRaiseToken: any = {
    'SCLP': '~466.96 BNB',
    'FORWARD': '~$100k BUSD'
  }

  const investors: any = {
    'SCLP': '617',
    'FORWARD': '802'
  }

  const desc = useMemo(() => {
    if (tokenSymbol === 'FORWARD') {
      return "Fully customizable toolkits for a decentralized value-driven economy with a no-code interface. WordPress for Web 3.0."
    } else {
      return "Scallop is an innovative fintech ecosystem for users that want a faster, more efficient way to securely manage their crypto + fiat in a single place."
    }
  }, [tokenSymbol])


  return (
    <Card
      // id={`${id.toLowerCase()}`}
      className="material-quaternary w-100 pointer"
      onClick={onProjectClick}
    >
      <Grid container spacing={2} direction='column'>
        <Grid item xs={12}>

          <div className="single-line-center-start m-b-12">
            <IconLoader
              iconName={tokenSymbol}
              iconType={"tokenSymbol"}
              className="m-r-24"
            />
            <div>
              <TextWrapper
                text={tokenSymbol}
                fontSize={24}
                fontWeight={600}
                lineHeight={'32px'}
              />
              {/*<TokenPrice tokenSymbol={tokenSymbol} />*/}
            </div>
          </div>
          <div className={"m-b-24"}>
            <TextWrapper
              text={desc}
              lineHeight={'24px'}
              fontSize={16}
              Fcolor={theme.color.transparent[100]}
            />
          </div>
          <div className="single-line-center-between">
            <div>
              <Tag
                color={theme.color.red[500]}
                bg={theme.color.red[100]}
              >
                closed
              </Tag>
            </div>
            <div>
              <MaterialToolTip
                toolTipContent={"Launching on Binance Smart Chain"}
              >
                <IconLoader
                  iconName={"BSC"}
                  iconType={"chains"}
                  width={24}
                  className={"m-l-12"}
                />
              </MaterialToolTip>
            </div>
          </div>
        </Grid>
        <Divider/>
        <Grid>
          <MobileCard>
            <DataField
              label={"Target raise"}
              isValueLoading={false}
              value={`${targetRaiseDollar[props.tokenSymbol]}`}
              labelFontSize={18}
              labelFontColor={theme.color.transparent[100]}
              valueFontSize={18}
              valueFontColor={"white"}
              valueFontWeight={600}
              className={'m-b-2'}
            />
            <DataField
              isValueLoading={false}
              value={`${targetRaiseToken[props.tokenSymbol]}`}
              valueFontColor={theme.color.transparent[100]}
              valueFontSize={12}
              valueFontWeight={600}
              className={'m-b-16'}
            />
            <DataField
              label={"Investors"}
              isValueLoading={false}
              value={`${investors[props.tokenSymbol]}`}
              labelFontSize={18}
              labelFontColor={theme.color.transparent[100]}
              valueFontSize={18}
              valueFontColor={"white"}
              valueFontWeight={600}
            />
          </MobileCard>
        </Grid>
      </Grid>
    </Card>
  );
}

export default ProjectCard;

const Card = styled.div`
  height: 100%;
  min-height: 420px;
  transition: 0.5s ease-in-out;
  @media (max-width: 600px) {
    border-radius: 0;
    padding: 32px 26px;
  }
`;

export const Tag = styled.div<{ color: string; bg: string }>`
  padding: 4px 8px;
  border-radius: 60px;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  background: ${(props) => {
    return props.bg;
  }};
  color: ${(props) => {
    return props.color;
  }};
`;

const MobileCard = styled.div`
  border-radius: 6px;
    /* background: ${theme.color.transparent[500]}; */
  padding: 0 20px 0 20px;
  @media (max-width: 600px) {
    border-radius: 0;
    background: transparent;
    padding: 0;
  }
`;
