import {useCallback} from "react";
import {ITiers} from "../../../PeopleOfEden/config";

import {useAddPopup} from "../../../state/application/hooks";
import {useTransactionAdder} from "../../../state/transactions/hooks";
import formatErrorMessage from "../../../utils/formatErrorMessage";
import {useGetAccount, useGetChainId} from "../../../utils/NetworksCustomHooks";
import useCore from "../../useCore";

export default function (tierDetails: ITiers, proof: string[] | null) {
  const core = useCore();
  const addPopup = useAddPopup();
  const addTransaction = useTransactionAdder();
  const chainId = useGetChainId();
  const contract = core?.getClaimingContract(chainId, tierDetails.contract);

  return useCallback(
    async (onInitiating: () => void, onSuccess: (nftId: string) => void, onFailure: (msg: string) => void): Promise<void> => {
      try {
        const response = await contract.mint(proof);
        onInitiating();

        addTransaction(response, {
          summary: `Mint citizenship`,
        });

        const tx = await response.wait();
        const getLogWithNFTId = tx.logs[0].topics[3];

        if (tx?.status === 1) onSuccess(parseInt(getLogWithNFTId).toString());
        if (tx?.status !== 1) onFailure('');

      } catch (e: any) {
        console.log('useClaim error', e?.message);
        addPopup({
          error: {
            message: formatErrorMessage(e?.data?.message || e?.message),
            stack: e.stack,
          },
        });
        onFailure(formatErrorMessage(e?.data?.message || e?.message));
      }
    },
    [proof, addPopup, contract, addTransaction],
  );
}
