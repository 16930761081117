import {IABIS} from '../../utils/interface';
import IERC20 from './abi/IERC20.json';
import MockERC20 from './abi/MockERC20.json'
import MAHAXMint from './abi/MAHAXMint.json'

const abis: IABIS = {
  IERC20,
  MockERC20,
  MAHAXMint,
};

export default abis;
