import {Grid} from "@material-ui/core";
import React from "react";
import Page from "../components/Page";


import TextWrapper from "../components/TextWrapper";

import {listOfProjects} from "../config";
import PeopleOfEdenHomeCard from "../PeopleOfEden/components/HomeCard";
import ApplyForLaunch from "./components/ApplyForLaunch";
import Footer from "./components/Footer";
import HeroSection from "./components/HeroSection";
import ProjectCard from "./components/ProjectCard";

const Home = () => {
  return (
    <div>
      <HeroSection/>
      <Page>
        <div className={"custom-container"}>
          <section>
            <div className="single-line-center-between m-b-40 mo-custom-container" id={"#pools"}>
              <TextWrapper
                text={"Projects"}
                fontSize={32}
                fontWeight={"bold"}
                lineHeight={'44px'}
              />
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <PeopleOfEdenHomeCard/>
              </Grid>
              {Object.keys(listOfProjects).map((project: string, i: number) => {
                let arrayOfIds: any[] = [];

                arrayOfIds.push(listOfProjects[project].projectName)

                return (
                  <Grid item key={`${i}:${project}:${Date.now()}`} xs={12} md={6} lg={4}>
                    <ProjectCard
                      tokenSymbol={project}
                      ids={listOfProjects[project].projectName}
                      arrayOfIds={Object.keys(listOfProjects).map(proj => [listOfProjects[proj].projectName])}/>
                  </Grid>
                )
              })}
              <Grid item xs={12} md={6} lg={4}>
                <ApplyForLaunch/>
              </Grid>
            </Grid>

          </section>
        </div>
      </Page>
      <Footer/>
    </div>
  );
}

export default Home;
