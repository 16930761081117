import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);
const env_check = process.env.REACT_APP_MIXPANEL_CHECK === 'true';
export const isProduction = process.env.REACT_APP_MODE === 'production';

let actions = {
  identify: (id) => {
    if (env_check) {
      mixpanel.identify(id);
    }
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) {
      mixpanel.track(name, props);
    }
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
