export const getStatus = (startAndEndDate: { startDate: number; endDate: number }): string => {
  if (startAndEndDate.startDate * 1000 > Date.now()) return "upcoming";

  if (Date.now() < startAndEndDate.endDate * 1000) {
    if (startAndEndDate.endDate * 1000 < Date.now()) return "live";
    /* if (startAndEndDate.endDate * 1000 - Date.now() <= DAY_IN_MS)
       return "24hrsLeft";*/
    else
      return "live";
  } else return "closed";
}

export type IRegistrationStatus =
  "Registration starting soon"
  | "Registration started"
  | "Registration closed"
  | "Registration dates will be announced soon";

export const getRegistrationStatus = (startAndEndDate: { startDate: number; endDate: number }): IRegistrationStatus => {
  if (startAndEndDate.startDate === 0) {
    console.log('dates', startAndEndDate.startDate === 0);
    return "Registration dates will be announced soon";
  }
  if (startAndEndDate.startDate * 1000 > Date.now()) return "Registration starting soon";

  if (Date.now() < startAndEndDate.endDate * 1000) {
    if (startAndEndDate.endDate * 1000 < Date.now()) return "Registration started";
    /* if (startAndEndDate.endDate * 1000 - Date.now() <= DAY_IN_MS)
       return "24hrsLeft";*/
    else
      return "Registration started";
  } else return "Registration closed";
}
